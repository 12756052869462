import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PaymentDetailsDialog = ({ open, onClose, payment }) => {
  if (!payment) {
    return <div></div>; // payment objesi gelene kadar bir yükleme mesajı gösterebilirsiniz
  }
  const { t, i18n } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>{t('detailInformation')}</DialogTitle>
      <DialogContent>
        <Typography sx={{ color: 'black' }}><strong>id :</strong> {payment.id}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('paymentName')} :</strong> {payment.name}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('paymentSurname')} :</strong> {payment.surname}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('email')} :</strong> {payment.email}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('phone')} :</strong> {payment.phone ? payment.phone : 'Veri yok'}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('profileAddress')} :</strong> {payment.address ? payment.address : 'Veri yok'}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('packageName')} :</strong> {payment.packageName}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('activityStatus')} :</strong> {payment.packageStatusDisplayName}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('beginning')} :</strong> {new Date(payment.startingDate).toLocaleDateString('tr-TR')}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('ending')} :</strong> {new Date(payment.endDate).toLocaleDateString('tr-TR')}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('maxUserCount')} :</strong> {payment.maxPartipicant}</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('totalAmount')} :</strong> {payment.price} ₺</Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('evaluationPeriod')} :</strong> {payment.canCancel ? t('yes') : t('no')} </Typography>
        <Typography sx={{ color: 'black' }}><strong> {t('createdDate')} :</strong> {new Date(payment.created).toLocaleDateString('tr-TR')}</Typography>
      </DialogContent>
    </Dialog>
  );
};

// PropTypes Tanımlaması
PaymentDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    address: PropTypes.string,
    packageName: PropTypes.string.isRequired,
    packageStatusDisplayName: PropTypes.string.isRequired,
    startingDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    maxPartipicant: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    canCancel: PropTypes.bool.isRequired,
    created: PropTypes.string.isRequired,
  }).isRequired,
};

export default PaymentDetailsDialog;
