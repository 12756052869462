// Components/UserTable.js
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const UserTable = ({
                     users,
                     sortConfig,
                     page,
                     rowsPerPage,
                     totalCount,
                     onSort,
                     onPageChange,
                     onRowsPerPageChange,
                     onEdit,
                     onDelete,
                   }) => {
  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <ArrowDropUpIcon fontSize='small' />
      ) : (
        <ArrowDropDownIcon fontSize='small' />
      );
    }
    return null;
  };
  const { t, i18n } = useTranslation();

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => onSort('fullName')} style={{ cursor: 'pointer' }}>
                {t('name')} {renderSortIcon('fullName')}
              </TableCell>
              <TableCell onClick={() => onSort('email')} style={{ cursor: 'pointer' }}>
                {t('email')}  {renderSortIcon('email')}
              </TableCell>
              <TableCell onClick={() => onSort('companyName')} style={{ cursor: 'pointer' }}>
                {t('company')}  {renderSortIcon('companyName')}
              </TableCell>
              <TableCell onClick={() => onSort('created')} style={{ cursor: 'pointer' }}>
                {t('beginning')} {renderSortIcon('created')}
              </TableCell>
              <TableCell onClick={() => onSort('endDate')} style={{ cursor: 'pointer' }}>
                {t('ending')} {renderSortIcon('endDate')}
              </TableCell>
              <TableCell onClick={() => onSort('userPackageName')} style={{ cursor: 'pointer' }}>
                {t('accounttype')} {renderSortIcon('userPackageName')}
              </TableCell>
              <TableCell>id</TableCell>
              <TableCell>Admin</TableCell>
              <TableCell>{t('active')} </TableCell>
              <TableCell>{t('edit')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{user.fullName}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{user.email}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{user.companyName}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{user.created}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{user.endDate}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{user.userPackageName}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{user.id}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>
                  {user.isAdmin ? <CheckIcon color='success' /> : null}
                </TableCell>

                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>
                  {user.isActive ? <CheckIcon color='success' /> : null}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
                      color='black'
                      onClick={() => onEdit(user)}
                      sx={{ fontSize: 'small' }}
                    >
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>
                    {/*
                    <IconButton
                      color='primary'
                      onClick={() => onDelete(user.id)}
                    >
                      <DeleteIcon style={{ fontSize: 18 }} />
                    </IconButton>
                    */}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={totalCount}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

UserTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      userPackageName: PropTypes.string.isRequired,
      isAdmin: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UserTable;
