import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListIcon from '@mui/icons-material/List';
import { TextField, InputAdornment, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import UserServices from '../../../../../services/userServices';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

const Companies = () => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState('');
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  // Yeni firma adı için state değişkeni
  const [newCompanyName, setNewCompanyName] = useState('');
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Firma verilerini al
    UserServices.getAllCompanies()
      .then((res) => {
        setCompanyData(res.data);
      })
      .catch((error) => {
        console.error('Veri alınırken hata oluştu:', error);
      })
      .finally(() => {
        setLoading(false);
      });

    // Tüm kullanıcıları al
    UserServices.getAllUsers()
      .then((res) => {
        setAllUsers(res.data);
      })
      .catch((error) => {
        console.error('Kullanıcılar alınırken hata oluştu:', error);
      });
  }, []);

  const getSortedAndFilteredCompanies = () => {
    const filteredCompanies = companyData.filter((company) =>
      (company.name?.toLowerCase().includes(filterText.toLowerCase()) ||
        company.email?.toLowerCase().includes(filterText.toLowerCase()) ||
        company.companyPackageName?.toLowerCase().includes(filterText.toLowerCase()) ||
        company.userCount?.toString().includes(filterText))
    );

    const sortedCompanies = [...filteredCompanies].sort((a, b) => {
      if (sortConfig.key) {
        const directionMultiplier = sortConfig.direction === 'asc' ? 1 : -1;
        if (a[sortConfig.key] < b[sortConfig.key]) return -1 * directionMultiplier;
        if (a[sortConfig.key] > b[sortConfig.key]) return 1 * directionMultiplier;
        return 0;
      }
      return 0;
    });

    return sortedCompanies;
  };

  const paginatedCompanies = getSortedAndFilteredCompanies().slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <ArrowDropUpIcon fontSize='small' />
      ) : (
        <ArrowDropDownIcon fontSize='small' />
      );
    }
    return null;
  };

  const handleEmployeeList = (companyName) => {
    // Seçilen firmanın adını state'e kaydet
    setSelectedCompanyName(companyName);

    // Firma adına göre çalışanları filtrele
    const filteredEmployees = allUsers.filter((user) => user.companyName === companyName);
    setEmployees(filteredEmployees);

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true); // Firma ekleme dialogunu aç
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false); // Firma ekleme dialogunu kapat
  };

  const handleAddCompany = () => {
    if (newCompanyName) {
      // Firma ekleme işlemi burada yapılacak
      console.log('Yeni Firma Eklendi:', newCompanyName);
      handleCloseAddDialog();
    } else {
      alert('Firma adı girin.');
    }
  };

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <Box>
      {/*
      <Button
        variant='contained'
        color='primary'
        startIcon={<AddIcon />}
        onClick={handleOpenAddDialog}
        style={{ marginBottom: '16px' }}
      >
        Firma Ekle
      </Button>
      */}
      <Box display='flex' justifyContent='space-between' mb={2}>
        <TextField
          variant='outlined'
          fullWidth
          margin='normal'
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                {t('name')}  {renderSortIcon('name')}
              </TableCell>
              <TableCell onClick={() => requestSort('email')} style={{ cursor: 'pointer' }}>
                {t('email')} {renderSortIcon('email')}
              </TableCell>
              <TableCell onClick={() => requestSort('created')} style={{ cursor: 'pointer' }}>
                {t('beginning')} {renderSortIcon('created')}
              </TableCell>
              <TableCell onClick={() => requestSort('endDate')} style={{ cursor: 'pointer' }}>
                {t('ending')} {renderSortIcon('endDate')}
              </TableCell>
              <TableCell onClick={() => requestSort('companyPackageName')} style={{ cursor: 'pointer' }}>
                {t('accountType')} {renderSortIcon('companyPackageName')}
              </TableCell>
              <TableCell onClick={() => requestSort('userCount')} style={{ cursor: 'pointer' }}>
                {t('userCount')} {renderSortIcon('userCount')}
              </TableCell>
              <TableCell>{t('list')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCompanies.map((company, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{company.name || 'Veri Yok'}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{company.email || 'Veri Yok'}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{company.created || 'Veri Yok'}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{company.endDate || 'Veri Yok'}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{company.companyPackageName || 'Veri Yok'}</TableCell>
                <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{company.userCount || 0}</TableCell>
                <TableCell>

                  <IconButton onClick={() => handleEmployeeList(company.name)}>
                    <ListIcon color='black' style={{ fontSize: 18 }}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component='div'
        count={getSortedAndFilteredCompanies().length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Employee List Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{`${selectedCompanyName} ${t('employeeList')}`}</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('email')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee, index) => (
                <TableRow key={index}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='primary'>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Company Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>{t('addCompany')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label={t('companyName')}
            fullWidth
            variant='outlined'
            value={newCompanyName}
            onChange={(e) => setNewCompanyName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color='primary'>
            {t('cancel')}
          </Button>
          <Button onClick={handleAddCompany} color='primary'>
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Companies;
