import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, InputAdornment, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PaymentTable from './PaymentTable';
import PaymentDetailsDialog from './PaymentDetailsDialog';
import { PricingServices } from '../../../../../services';
import { useTranslation } from 'react-i18next';

const Payments = () => {
  const [filterText, setFilterText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [currentPayment, setCurrentPayment] = useState(null);
  const [currentPaymentsData, setCurrentPaymentsData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    PricingServices.getAllPayments()
      .then((res) => {
        if (Array.isArray(res.data.result)) {
          setCurrentPaymentsData(res.data.result);
        } else {
          setCurrentPaymentsData([]);
        }
      })
      .catch((error) => {
        console.error('Veri çekme hatası:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sortedPayments = [...currentPaymentsData].sort((a, b) => {
    const aValue =
      sortConfig.key === 'date'
        ? new Date(a.created)
        : sortConfig.key === 'price'
          ? parseFloat(a.price) || 0 // Burada price verisini sayıya çeviriyoruz ve sayısal olmayan bir değer varsa 0 atıyoruz
          : a[sortConfig.key];

    const bValue =
      sortConfig.key === 'date'
        ? new Date(b.created)
        : sortConfig.key === 'price'
          ? parseFloat(b.price) || 0
          : b[sortConfig.key];

    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const filteredPayments = sortedPayments.filter((payment) => {
    const date = payment.created ? payment.created : '';
    const email = payment.email ? payment.email.toLowerCase() : '';
    const price = payment.price ? payment.price.toString() : '';

    return (
      date.includes(filterText.toLowerCase()) ||
      email.includes(filterText.toLowerCase()) ||
      price.includes(filterText.toLowerCase())
    );
  });

  const paginatedPayments = filteredPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDetailsDialog = (payment) => {
    setCurrentPayment(payment);
    setOpenDetailsDialog(true);
  };

  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setCurrentPayment(null);
  };

  return (
    <Box>

      <TextField
        variant='outlined'
        fullWidth
        margin='normal'
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}

        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <PaymentTable
          payments={paginatedPayments}
          sortConfig={sortConfig}
          onSort={handleSort}
          page={page}
          rowsPerPage={rowsPerPage}
          totalCount={filteredPayments.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onViewDetails={handleOpenDetailsDialog}
        />
      )}

      <PaymentDetailsDialog
        open={openDetailsDialog}
        onClose={handleCloseDetailsDialog}
        payment={currentPayment}
      />
    </Box>
  );
};

export default Payments;
