import React, { useEffect, useState } from 'react';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import UserServices from '../../../../../services/userServices';
import { PricingServices } from '../../../../../services';
import AddUserDialog from './Components/AddUserDialog';
import EditUserDialog from './Components/EditUserDialog';
import UserTable from './Components/UserTable';

const Users = () => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [addFormData, setAddFormData] = useState({
    name: '',
    email: '',
    password: '',
    passwordRepeat: '',
    company: '',
    startDate: '',
    endDate: '',
    membershipType: '',
    isAdmin: false,
  });
  const [editFormData, setEditFormData] = useState({
    name: '',
    surname: '',
    email: '',
    company: '',
    startDate: '',
    endDate: '',
    membershipType: '',
    isAdmin: false,
  });

  useEffect(() => {
    UserServices.getAllUsers().then((res) => {
      setUserData(res.data);
    });

    UserServices.getAllCompanies().then((res) => {
      setCompanyList(res.data);
    });

    PricingServices.getAllPackages('false').then((res) => {
      setPackageList(res.data);
    });
  }, []);

  const handleOpenEditDialog = (user) => {
    setCurrentUser(user);
    setEditFormData({
      id: user.id,
      name: user.name,
      surname: user.surname,
      email: user.email,
      company: user.companyName,
      startDate: user.created,
      endDate: user.endDate,
      membershipType: user.userPackageName,
      isAdmin: user.isAdmin,
      isActive: user.isActive,
    });
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCurrentUser(null);
  };

  const handleSaveUser = () => {
    const updatedUser = {
      ...currentUser,
      name: editFormData.name,
      surname: editFormData.surname,
      email: editFormData.email,
      companyName: editFormData.company,
      created: editFormData.startDate,
      endDate: editFormData.endDate,
      userPackageName: editFormData.membershipType,
      isDashboardUser: editFormData.isAdmin,
      companyId: companyList.filter(x => x.name === editFormData.company)[0].companyId,
    };

    UserServices.editUser(updatedUser).then(() => {
      setUserData((prevData) =>
        prevData.map((user) => (user.id === updatedUser.id ? updatedUser : user)),
      );

      UserServices.getAllUsers().then((res) => {
        setUserData(res.data);
      });

      handleCloseEditDialog();
    });
  };

  const handleOpenAddDialog = () => {
    resetFormFields();
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAddUser = () => {
    if (addFormData.password !== addFormData.passwordRepeat) {
      alert('Şifreler eşleşmiyor!');
      return;
    }

    const newUser = {
      name: addFormData.name,
      email: addFormData.email,
      password: addFormData.password,
      companyName: addFormData.company,
      created: addFormData.startDate,
      endDate: addFormData.endDate,
      userPackageName: addFormData.membershipType,
      isAdmin: addFormData.isAdmin,
    };

    UserServices.addUser(newUser).then((res) => {
      setUserData((prevData) => [...prevData, res.data]);
      handleCloseAddDialog();
    });
  };

  const handleDeleteUser = (userId) => {
    if (window.confirm('Bu kullanıcıyı silmek istediğinize emin misiniz?')) {
      UserServices.deleteUser(userId).then(() => {
        setUserData((prevData) => prevData.filter((user) => user.id !== userId));
      });
    }
  };

  const resetFormFields = () => {
    setEditFormData({
      name: '',
      email: '',
      password: '',
      passwordRepeat: '',
      company: '',
      startDate: '',
      endDate: '',
      membershipType: '',
      isAdmin: false,
    });
  };

  const getSortedAndFilteredUsers = () => {
    const filteredUsers = userData
      .filter(
        (user) =>
          user.fullName.toLowerCase().includes(filterText.toLowerCase()) ||
          user.email.toLowerCase().includes(filterText.toLowerCase()) ||
          user.companyName.toLowerCase().includes(filterText.toLowerCase()) ||
          user.userPackageName.toLowerCase().includes(filterText.toLowerCase()),
      )
      .map((user) => ({
        ...user,
        created: new Date(user.created).toISOString().split('T')[0],
        endDate: new Date(user.endDate).toISOString().split('T')[0],
      }));

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (sortConfig.key) {
        const directionMultiplier = sortConfig.direction === 'asc' ? 1 : -1;
        if (a[sortConfig.key] < b[sortConfig.key]) return -1 * directionMultiplier;
        if (a[sortConfig.key] > b[sortConfig.key]) return 1 * directionMultiplier;
        return 0;
      }
      return 0;
    });

    return sortedUsers;
  };

  const paginatedUsers = getSortedAndFilteredUsers().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <TextField
        variant='outlined'
        fullWidth
        margin='normal'
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <UserTable
        users={paginatedUsers}
        sortConfig={sortConfig}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={getSortedAndFilteredUsers().length}
        onSort={requestSort}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onEdit={handleOpenEditDialog}
        onDelete={handleDeleteUser}
      />

      <EditUserDialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        onSave={handleSaveUser}
        companyList={companyList}
        packageList={packageList}
        formData={editFormData}
        setFormData={setEditFormData}
      />

      <AddUserDialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onAdd={handleAddUser}
        formData={addFormData}
        setFormData={setAddFormData}
        companyList={companyList}
        packageList={packageList}
      />
    </Box>
  );
};

export default Users;
