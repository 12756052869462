import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

const AddUserDialog = ({
                         open,
                         onClose,
                         onAdd,
                         formData,
                         setFormData,
                         companyList,
                         packageList,
                       }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      isAdmin: e.target.checked,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Kullanıcı Ekle</DialogTitle>
      <DialogContent>
        <TextField
          label='Ad Soyad'
          variant='outlined'
          fullWidth
          margin='normal'
          name='name'
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label='Email'
          variant='outlined'
          fullWidth
          margin='normal'
          name='email'
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label='Şifre'
          variant='outlined'
          fullWidth
          margin='normal'
          type='password'
          name='password'
          value={formData.password}
          onChange={handleChange}
        />
        <TextField
          label='Şifre Tekrar'
          variant='outlined'
          fullWidth
          margin='normal'
          type='password'
          name='passwordRepeat'
          value={formData.passwordRepeat}
          onChange={handleChange}
        />
        <FormControl fullWidth margin='normal'>
          <InputLabel>Firma</InputLabel>
          <Select
            value={formData.company}
            name='company'
            onChange={handleChange}
            label='Firma'
          >
            {companyList.map((company) => (
              <MenuItem key={company.id} value={company.name}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Başlangıç Tarihi'
          variant='outlined'
          fullWidth
          margin='normal'
          name='startDate'
          value={formData.startDate}
          onChange={handleChange}
        />
        <TextField
          label='Bitiş Tarihi'
          variant='outlined'
          fullWidth
          margin='normal'
          name='endDate'
          value={formData.endDate}
          onChange={handleChange}
        />
        <FormControl fullWidth margin='normal'>
          <InputLabel>Üyelik Tipi</InputLabel>
          <Select
            value={formData.membershipType}
            name='membershipType'
            onChange={handleChange}
            label='Üyelik Tipi'
          >
            {packageList.map((packg) => (
              <MenuItem key={packg.id} value={packg.title}>
                {packg.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isAdmin}
              onChange={handleCheckboxChange}
            />
          }
          label='Admin'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          İptal
        </Button>
        <Button onClick={onAdd} color='primary'>
          Ekle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    passwordRepeat: PropTypes.string,
    company: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    membershipType: PropTypes.string,
    isAdmin: PropTypes.bool,
  }),
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  packageList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ).isRequired,
  setFormData: PropTypes.func.isRequired,
};

AddUserDialog.defaultProps = {
  formData: {
    name: '',
    email: '',
    password: '',
    passwordRepeat: '',
    company: '',
    startDate: '',
    endDate: '',
    membershipType: '',
    isAdmin: false,
  },
};

export default AddUserDialog;
