import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, List, ListItem, ListItemText, Paper, Typography, Card, CardHeader, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DashboardService } from '../../../../../services';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  
  useEffect(() => {
    DashboardService.getDashboardData()
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error('Veri alınırken hata oluştu:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (!data) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">{t('noDataAvailable')}</Typography>
      </Box>
    );
  }
  
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('dashboard')}
      </Typography>
      <Grid container spacing={3}>
        {/* Genel İstatistikler */}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Paper sx={{ padding: 2, border: '1px solid #ddd', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboardLiveData')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title={t('dashboardActiveMeetings')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.activeMeetings}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title={t('dashboardOnlineUsers')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.onlineUsers}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Paper sx={{ padding: 2, border: '1px solid #ddd', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboardUserAndCompanyStatistics')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title={t('dashboardRegisteredUsers')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.totalUsers}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title={t('dashboardRegisteredCompanies')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.totalCompanies}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        {/* Toplantı İstatistikleri */}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Paper sx={{ padding: 2, border: '1px solid #ddd', width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboardMeetingStatistics')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardHeader title={t('packetsDaily')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.meetingsToday}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardHeader title={t('packetsWeekly')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.meetingsThisWeek}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardHeader title={t('packetsMonthly')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.meetingsThisMonth}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardHeader title={t('packetsYearly')} />
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>{data.meetingsThisYear}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        
        {/* Son Kullanıcılar ve Şirketler */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboardLastUsers')}
            </Typography>
            <List>
              {data.latestUsers.map((user) => (
                <ListItem key={user.id}>
                  <ListItemText primary={user.fullName} secondary={user.email} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboardLastCompanies')}
            </Typography>
            <List>
              {data.latestCompanies.map((company) => (
                <ListItem key={company.id}>
                  <ListItemText primary={company.name}  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        
        {/* En Aktif Kullanıcılar ve Şirketler */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboardMostUsers')}
            </Typography>
            <List>
              {data.topUsers.map((user) => (
                <ListItem key={user.id}>
                  <ListItemText primary={user.name} secondary={`${t('meetingCount')}: ${user.meetings}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, border: '1px solid #ddd' }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboardMostCompanies')}
            </Typography>
            <List>
              {data.topCompanies.map((company) => (
                <ListItem key={company.id}>
                  <ListItemText primary={company.name} secondary={`${t('meetingCount')}: ${company.meetings}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
