import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
} from '@mui/material';
import UserServices from '../../../../../../services/userServices';

const EditUserDialog = ({
                          open,
                          onClose,
                          onSave,
                          userData,
                          companyList,
                          packageList,
                          formData,
                          setFormData,
                        }) => {
  useEffect(() => {
    if (userData) {
      setFormData({
        ...formData,
        id: userData.id,
        name: userData.name,
        surname: userData.surname,
        email: userData.email,
        company: userData.company,
        startDate: userData.startDate,
        endDate: userData.endDate,
        membershipType: userData.membershipType,
        isAdmin: userData.isAdmin,
        isActive: userData.isActive,
      });
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      isAdmin: e.target.checked,
    }));
  };

  const handleToggleActive = () => {
    if (!formData || !formData.id) {
      console.error('Kullanıcı verisi mevcut değil veya ID bulunamadı');
      return;
    }

    const action = formData.isActive
      ? UserServices.deactivateUser(formData.id)
      : UserServices.activateUser(formData.id);

    action
      .then(() => {
        console.log(`Kullanıcı başarıyla ${formData.isActive ? 'pasif' : 'aktif'} hale getirildi`);
        setFormData((prev) => ({
          ...prev,
          isActive: !prev.isActive,
        }));
      })
      .catch((error) => {
        console.error('Aktivasyon/Deaktivasyon hatası:', error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Kullanıcı Düzenle</DialogTitle>
      <DialogContent>
        {/* ID Alanı */}
        <TextField
          label='ID'
          variant='outlined'
          fullWidth
          margin='normal'
          name='id'
          value={formData.id || ''}
          disabled
        />

        {/* Toggle Switch ile aktiflik durumu */}
        <FormControlLabel
          control={
            <Switch
              checked={formData.isActive}
              onChange={handleToggleActive}
              color='primary'
            />
          }
          label={formData.isActive ? 'Aktif' : 'Pasif'}
        />
        <TextField
          label='Ad'
          variant='outlined'
          fullWidth
          margin='normal'
          name='name'
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label='Soyad'
          variant='outlined'
          fullWidth
          margin='normal'
          name='surname'
          value={formData.surname}
          onChange={handleChange}
        />
        <TextField
          label='Email'
          variant='outlined'
          fullWidth
          margin='normal'
          name='email'
          value={formData.email}
          onChange={handleChange}
        />
        <FormControl fullWidth margin='normal'>
          <InputLabel>Firma</InputLabel>
          <Select
            value={formData.company}
            name='company'
            onChange={handleChange}
            label='Firma'
          >
            {companyList.map((company) => (
              <MenuItem key={company.id} value={company.name}>
                {company.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Başlangıç Tarihi'
          variant='outlined'
          fullWidth
          margin='normal'
          name='startDate'
          disabled
          value={formData.startDate}
        />
        <TextField
          label='Bitiş Tarihi'
          variant='outlined'
          fullWidth
          margin='normal'
          name='endDate'
          value={formData.endDate}
          onChange={handleChange}
        />
        <FormControl fullWidth margin='normal'>
          <InputLabel>Üyelik Tipi</InputLabel>
          <Select
            value={formData.membershipType}
            name='membershipType'
            onChange={handleChange}
            label='Üyelik Tipi'
          >
            {packageList.map((packg) => (
              <MenuItem key={packg.id} value={packg.title}>
                {packg.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isAdmin}
              onChange={handleCheckboxChange}
            />
          }
          label='Admin'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          İptal
        </Button>
        <Button onClick={onSave} color='primary'>
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  userData: PropTypes.object,
  companyList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  packageList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  formData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    membershipType: PropTypes.string,
    isAdmin: PropTypes.bool,
    isActive: PropTypes.bool,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
};

EditUserDialog.defaultProps = {
  userData: null,
};

export default EditUserDialog;
