import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';


const PaymentTable = ({
                        payments,
                        sortConfig,
                        onSort,
                        page,
                        rowsPerPage,
                        totalCount,
                        onPageChange,
                        onRowsPerPageChange,
                        onViewDetails
                      }) => {
  const handleSortRequest = (column) => {
    onSort(column);
  };
  const { t, i18n } = useTranslation();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              onClick={() => handleSortRequest('date')}
              style={{ cursor: 'pointer' }}
            >
              {t('date')} {sortConfig.key === 'date' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
            </TableCell>
            <TableCell
              onClick={() => handleSortRequest('email')}
              style={{ cursor: 'pointer' }}
            >
              {t('email')} {sortConfig.key === 'email' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
            </TableCell>
            <TableCell
              onClick={() => handleSortRequest('amount')}
              style={{ cursor: 'pointer' }}
            >
              {t('totalAmount')} {sortConfig.key === 'amount' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : ''}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <TableRow key={payment.id} onClick={() => onViewDetails(payment)}>
              <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{new Date(payment.created).toLocaleDateString('tr-TR')}</TableCell>
              <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{payment.email}</TableCell>
              <TableCell sx={{ color: 'black', fontSize: 'x-small' }}>{payment.price}</TableCell>
              <TableCell align='center'>
                <InfoIcon
                  onClick={() => onViewDetails(payment)}
                  sx={{ cursor: 'pointer', color: 'red' }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>

  );
};

PaymentTable.propTypes = {
  payments: PropTypes.array.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export default PaymentTable;
