import { Ajax } from '../utils/index';

export default {
  startTrial(model) {
    let promise = Ajax.postPromise('/payment/start-trial', model);
    return promise;
  },
  paymentRequest(selected) {
    let promise = Ajax.postPromise('/payment/pay', selected);
    return promise;
  },
  getAllPackages(isCurrencyDollar) {
    let promise = Ajax.getPromise('/payment/get-all-products/' + isCurrencyDollar);
    return promise;
  },
  getCurrentProduct() {
    let promise = Ajax.getPromise('/payment/get-current-product');
    return promise;
  },
  searchWithIdentity(identityNumber) {
    let promise = Ajax.getPromise('/payment/get-user-from-nps-service/' + identityNumber);
    return promise;
  },
  cancelPayment(id, invoiceNo) {
    let promise = Ajax.getPromise('/payment/cancel-payment/' + id + '/' + invoiceNo);
    return promise;
  },
  updatePackage(id, data) {
    let promise = Ajax.postPromise('/payment/update-package', { ...data, id });
    return promise;
  },
  getAllPayments() {
    let promise = Ajax.postPromise('/payment/get-all-payments');
    return promise;
  }
};
